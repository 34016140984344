<template>
  <div class="header-top">
    <div class="container">
      <div class="row no-gutters justify-content-between">
        <div class="header-top__logo">
          <a :href="baseUrlWithLocale" rel="home">
            <img :src="require('@javascript/packs/images/@priorbank/header/prior-logo.svg')" alt="PriorBank"/>
          </a>
        </div>
        <div class="header-top__menu d-none d-md-flex">
          <div class="header-top__menu--number">
            <img :src="require('@javascript/packs/images/@priorbank/header/phone.png')" alt="mobile" />
            <a :href="`tel:${callCenterPhone}`">{{ callCenterPhone }}</a>
          </div>
          <div class="header-top__menu--button-enter" v-if="!isLoginPage && !isLogin">
            <vl-button size="medium" type="third" @click.native.stop="moveTo">{{
              t[locale]['priorbank.header.header_top_menu.enter']
            }}</vl-button>
          </div>
          <div class="header-top__menu--button-user">
            <menu-desktop-button
              :is-login="isLogin"
              :user-name="userName"
              :profile-menu="profileMenu"
              :locale="locale"
              :base-url-with-locale="baseUrlWithLocale"
              :phones="phones"
            />
          </div>
          <div v-if="!isOrderPage" class="header-top__menu--lang">
            <language
              :available-locales="availableLocales"
              :current-path="currentPath"
              :locale="locale"
              :base-url-with-locale="baseUrlWithLocale"
            />
          </div>
        </div>
        <div class="header-top__menu-mobile d-md-none">
          <cart
            v-if="!isOrderPage && isLogin"
            :locale="locale"
            :default-locale="defaultLocale"
            :user-id="userData.id"
            :base-url-with-locale="baseUrlWithLocale"
          />
          <menu-mobile-button
            :is-login="isLogin"
            :user-name="userName"
            :user-data="userData"
            :profile-menu="profileMenu"
            :locale="locale"
            :base-url-with-locale="baseUrlWithLocale"
            :available-locales="availableLocales"
            :current-path="currentPath"
            :phones="phones"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AUTHORIZATION_LOGIN, ORDER_SHOW } from '@javascript/config/routes'
import { scrollToElementById } from '@javascript/utils/helper-methods'
import VlButton from '@components/@exim/ui/vl-button'
import MenuDesktopButton from './menu-button/menu-desktop-button/MenuDesktopButton'
import MenuMobileButton from './menu-button/menu-mobile-button/MenuMobileButton'
import Language from './language-container/LanguageContainer'
import Cart from './cart/Cart'
import locales from './locales'
import { redirectTo } from '../../../utils/helper-methods'
export default {
  name: 'HeaderTop',
  components: {
    VlButton,
    MenuDesktopButton,
    MenuMobileButton,
    Cart,
    Language,
  },
  props: {
    availableLocales: {
      type: String,
      default: '',
    },
    currentPath: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    isLogin: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: false,
    },
    profileMenu: {
      type: Array,
      default: null,
    },
    userData: {
      type: Object,
      default: null,
    },
    phones: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    t: locales,
  }),
  computed: {
    isLoginPage() {
      return window.location.pathname.includes(AUTHORIZATION_LOGIN)
    },
    isOrderPage() {
      const orderRouter = `${this.baseUrlWithLocale}${ORDER_SHOW}`
      return window.location.pathname == orderRouter
    },
    callCenterPhone() {
      if (Array.isArray(this.phones) && this.phones[0]) {
        return this.phones[0]
      }
      return ''
    },
  },
  methods: {
    moveTo() {
      try {
        scrollToElementById('login')
      } catch (e) {
        redirectTo(`${this.baseUrlWithLocale}${AUTHORIZATION_LOGIN}`)
      }
    },
  },
}
</script>
