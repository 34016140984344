<template>
  <div class="menu-logged-out">
    <modal v-if="showMobileMenu" @click="toggleMobileMenu">
      <template v-slot:header>
        <a href="/">
          <img
            class="menu-logged-out__logo"
            :src="require('@javascript/packs/images/@priorbank/header/prior-logo.svg')" alt="priorbank"
          />
        </a>
      </template>
      <template>
        <div class="menu">
          <div class="menu__top">
            <div class="menu__top--number" v-if="phones.length">
              <img
                :src="
                  require('@javascript/packs/images/@priorbank/header/phone.png')
                "
                alt
              />
              <a :href="`tel:${phones[0]}`">{{phones[0]}}</a>
            </div>
            <div class="menu__top--lang">
              <mobile-language-container
                v-if="isHideOnThisPage"
                :available-locales="availableLocales"
                :current-path="currentPath"
                :locale="locale"
                :base-url-with-locale="baseUrlWithLocale"
              />
            </div>
          </div>
          <div class="menu__middle">
            <vl-button
              v-if="!isLoginPage"
              size="medium"
              type="third"
              @click.native.stop="isHomeRouter ? clickByNav('login') : openLoginPage()"
              >{{
                t[locale]['priorbank.header.header_top_menu.enter']
              }}</vl-button
            >
            <vl-button
              size="medium"
              type="secondary"
              @click.native.stop="openRegistrationPage"
            >
              <span>{{
                t[locale]['priorbank.header.header_top_menu.register']
              }}</span>
            </vl-button>
          </div>
        </div>
        <hr v-if="isHomeRouter" />
        <ul class="menu__bottom" v-if="isHomeRouter">
          <li
            v-for="item of navItems"
            :key="item.value"
            class="menu__bottom--nav-item"
            @click="clickByNav(item.value)"
          >
            {{ item.name }}
          </li>
        </ul>
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from '../../modal'
import MobileLanguageContainer from '../../language-container/MobileLanguageContainer'
import VlButton from '@components/@priorbank/ui/vl-button'
import {
  MAIN,
  ORDER_SHOW,
  VERIFY_WELCOM,
  REGISTRATION_CARD,
  AUTHORIZATION_LOGIN,
} from '@javascript/config/routes'
import { redirectTo } from '@javascript/utils/helper-methods'
import locales from '../locales'
export default {
  name: 'MenuMobileLoggedIn',
  components: {
    Modal,
    MobileLanguageContainer,
    VlButton,
  },
  props: {
    showMobileMenu: {
      type: Boolean,
      default: false,
    },
    toggleMobileMenu: {
      type: Function,
      default: () => {},
    },
    locale: {
      type: String,
      default: '',
    },
    availableLocales: {
      type: String,
      default: '',
    },
    currentPath: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    isLogin: {
      type: Boolean,
      default: false,
    },
    phones: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      t: locales,
      navItems: [
        {
          name:
            locales[this.locale]['priorbank.header.header_botom_menu.catalog'],
          value: 'catalog',
        },
        {
          name:
            locales[this.locale][
              'priorbank.header.header_botom_menu.how_to_get_involved'
            ],
          value: 'involved',
        },
        {
          name:
            locales[this.locale][
              'priorbank.header.header_botom_menu.earning_points'
            ],
          value: 'earning-points',
        },
        {
          name: locales[this.locale]['priorbank.header.header_botom_menu.news'],
          value: 'news',
        },
      ],
    }
  },
  computed: {
    isOrderPage() {
      return window.location.pathname.includes(ORDER_SHOW)
    },
    isVerifyEmailPage() {
      return window.location.pathname.includes(VERIFY_WELCOM)
    },
    isHideOnThisPage() {
      return !this.isOrderPage && !this.isVerifyEmailPage
    },
    isLoginPage() {
      return window.location.pathname.includes(AUTHORIZATION_LOGIN)
    },
    isHomeRouter() {
      const currentRouter = document.location.pathname
      if (currentRouter === MAIN) {
        return true
      }
      return false
    },
  },
  methods: {
    clickByNav(value){
      this.$emit('clickByNav', value)
    },
    openRegistrationPage() {
      let link = `${this.baseUrlWithLocale}${REGISTRATION_CARD}`
      redirectTo(link)
    },
    openLoginPage() {
      let link = `${this.baseUrlWithLocale}${AUTHORIZATION_LOGIN}`
      redirectTo(link)
    },
  },
}
</script>
