<template>
  <div class="menu-logged-in">
    <modal v-if="showMobileMenu" @click="toggleMobileMenu">
      <template v-slot:header>
        <a href="/">
          <img
            class="menu-logged-out__logo"
            :src="require('@javascript/packs/images/@priorbank/header/prior-logo.svg')"
            alt="Priorbank"
          />
        </a>
      </template>
      <template>
        <div class="menu">
          <div class="menu__top">
            <div class="menu__top--number" v-if="phones.length">
              <img :src="require('@javascript/packs/images/@priorbank/header/phone.png')" alt />
              <a :href="`tel:${phones[0]}`">{{ phones[0] }}</a>
            </div>
            <div class="menu__top--lang">
              <mobile-language-container
                v-if="isHideOnThisPage"
                :available-locales="availableLocales"
                :current-path="currentPath"
                :locale="locale"
                :base-url-with-locale="baseUrlWithLocale"
              />
            </div>
          </div>
          <div class="menu__middle">
            <p class="menu__middle--greeting">
              {{ t[locale]['header.greeting'] }}
              <span>, {{ userName }}</span>
            </p>
            <div class="menu__middle--bonuses">
              {{ t[locale]['priorbank.header.header_botom_menu.your_balance'] }}
              <span>{{ balance }}</span>
              {{ currency }}

              <div class="bonuses-expire" v-if="userData.bonuses_will_expire > 0">
                <vl-tooltip :isCloseBtn="true">
                  <template #activator="on">
                    <div v-on="on" class="d-flex">
                      <vl-icon-base width="14" height="14" viewBoxWidth="14" viewBoxHeight="14" opacity="1">
                        <vl-icon-exclamation-mark />
                      </vl-icon-base>
                    </div>
                  </template>
                  <template>
                    <div>
                      {{ t[locale]['profile.my_balance.bonuses_can_expire'].replace('{bonuses}', userData.bonuses_will_expire) }}
                      <a :href="rulesPage" target="_blank">{{ t[locale]['profile.my_balance.rules'] }}</a>.
                    </div>
                  </template>
                </vl-tooltip>
              </div>

            </div>
          </div>
        </div>
        <hr />
        <ul class="menu__bottom">
          <li v-for="item of profileMenu" :key="item.id" class="menu__bottom--nav-item">
            <a :href="item.url">
              {{ item.title }}
            </a>
          </li>
          <li class="menu__bottom--nav-item">
            <a :href="authorizationLoginRouter" data-method="delete">{{ t[locale]['authorization.logout.exit'] }}</a>
          </li>
        </ul>
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from '../../modal'
import { ORDER_SHOW, VERIFY_WELCOM, AUTHORIZATION_LOGOUT } from '@javascript/config/routes'
import MobileLanguageContainer from '../../language-container/MobileLanguageContainer'
import VlTooltip from '@components/@priorbank/ui/vl-tooltip'
import VlIconBase from '@components/@priorbank/ui/vl-icons'
import VlIconExclamationMark from '@components/@priorbank/ui/vl-icons/VlIconeExclamationMark'

import locales from '../locales'
export default {
  name: 'MenuMobileLoggedIn',
  components: {
    Modal,
    MobileLanguageContainer,
    VlTooltip,
    VlIconBase,
    VlIconExclamationMark,
  },
  props: {
    userName: {
      type: String,
      default: false,
    },
    showMobileMenu: {
      type: Boolean,
      default: false,
    },
    toggleMobileMenu: {
      type: Function,
      default: () => {},
    },
    profileMenu: {
      type: Array,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
    availableLocales: {
      type: String,
      default: '',
    },
    currentPath: {
      type: String,
      default: '',
    },
    baseUrlWithLocale: {
      type: String,
      default: '',
    },
    userData: {
      type: Object,
      default: null,
    },
    phones: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    t: locales,
  }),
  computed: {
    isOrderPage() {
      return window.location.pathname.includes(ORDER_SHOW)
    },
    isVerifyEmailPage() {
      return window.location.pathname.includes(VERIFY_WELCOM)
    },
    isHideOnThisPage() {
      // return !this.isOrderPage && !this.isVerifyEmailPage
      return !this.isVerifyEmailPage
    },
    authorizationLoginRouter() {
      return `${this.baseUrlWithLocale}${AUTHORIZATION_LOGOUT}`
    },
    balance() {
      return this.userData?.balance
    },
    currency() {
      return this.userData?.site_currency
    },
    rulesPage() {
      return `${this.baseUrlWithLocale}/topic/rules`
    },
  },
}
</script>
